import { Injectable } from '@angular/core';
import { pick } from 'lodash';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import type { CompanyLicenseInfo } from '../../common-header/services/company-license-info';
import { AssignedDisplays, Company } from './company';
import { ApiService } from './api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ApiSearch } from './api-utils.service';
import { ApiListResponse, ApiResponse } from '../api';
import { CompanyTreeItem } from 'src/app/common-header/services/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyApiService extends ApiService<Company> {

  static readonly COMPANY_WRITABLE_FIELDS = [
    'name', 'street', 'unit', 'city', 'province', 'country',
    'postalCode', 'timeZoneOffset', 'telephone', 'fax', 'companyStatus',
    'mailSyncEnabled', 'shipToUseCompanyAddress',
    'shipToName', 'shipToStreet', 'shipToUnit', 'shipToCity',
    'shipToProvince', 'shipToPostalCode', 'shipToCountry', 'website',
    'companySize', 'companyIndustry', 'billingContactEmails', 'shareCompanyPlan', 'settings',
    'unlockAllFeatures'
  ];

  static readonly ALERTS_WRITABLE_FIELDS = [
    'alertSettings'
  ];

  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient, null);
  }

  override getResourceUrl(): string {
    return '/v2/companies';
  }

  override toServerModel(entity: Company): any {
    return pick(entity, CompanyApiService.COMPANY_WRITABLE_FIELDS);
  }

  async updateAlerts(companyId: string, company: any): Promise<any> {
    const fields = pick(company, CompanyApiService.ALERTS_WRITABLE_FIELDS);

    return firstValueFrom(super.requestAsObservable(companyId, 'PATCH', '', null, fields));
  }

  async getCompanies(search: ApiSearch & { companyId?: string, name?: string }, cursor?: string): Promise<ApiListResponse<CompanyTreeItem>> {
    const params = new HttpParams()
      .set('companyId', search.companyId)
      .set('search', search.query || '*')
      .set('filter', search.filter || '')
      .set('includeSubcompanies', search.includeSubcompanies || false)
      .set('cursor', cursor)
      .set('count', search.count)
      .set('sort', search.sortBy + (search.reverse ? ' desc' : ' asc'));

    return firstValueFrom(super.listAsTypedObservable(params));
  }

  async getCompany(id?: string): Promise<Company> {
    return firstValueFrom(super.getAsObservable(id || ''))
      .then(resp => resp.item);
  }

  async createCompany(parentCompanyId: string, company: any): Promise<Company> {
    const params = new HttpParams()
      .set('parentId', parentCompanyId);
    const fields = this.toServerModel(company);

    return firstValueFrom(super.requestAsObservable(null, 'POST', '', params, fields))
      .then(resp => resp.item);
  }

  async updateCompany(companyId: string, company: any): Promise<Company> {
    return firstValueFrom(super.updateAsObservable(companyId, company))
      .then(resp => resp.item);
  }

  async deleteCompany(id: string): Promise<Company> {
    return firstValueFrom(super.deleteAsObservable(id))
      .then(resp => resp.item);
  }

  async lookupCompany(authKey: string): Promise<Company> {
    const params = new HttpParams()
      .set('authKey', authKey);

    return firstValueFrom(super.requestAsObservable(null, 'GET', 'lookup', params))
      .then(resp => resp.item);
  }

  async moveCompany(authKey: string, newParentId: string): Promise<ApiResponse<string>> {
    const params = new HttpParams()
      .set('authKey', authKey)
      .set('newParentId', newParentId);

    return firstValueFrom(super.requestAsObservable(null, 'PATCH', 'move', params));
  }

  async exportCompany(companyId: string): Promise<ApiResponse<string>> {
    return firstValueFrom(super.requestAsObservable(companyId, 'GET', 'export'));
  }

  async regenerateField(companyId: string, fieldName: string): Promise<ApiResponse<string>> {
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('fieldName', fieldName);

    return firstValueFrom(super.requestAsObservable(null, 'PATCH', 'regenerate', params));
  }

  async enableCompanyProduct(companyId: string, licenseType: string, displayStatusMap: any): Promise<ApiResponse<AssignedDisplays>> {
    const params = new HttpParams()
      .set('licenseType', licenseType);

    return firstValueFrom(super.requestAsObservable(companyId, 'PATCH', 'enable-product', params, displayStatusMap));
  }

  async getLicenseAllocationInfo(companyId: string): Promise<{ item: { licenseInfo: CompanyLicenseInfo } }> {
    return firstValueFrom(super.requestAsObservable(companyId, 'GET', 'license-allocation-info'));
  }

  async allocateLicenses(companyId: string, companyLicenses: { [key: string]: any }, licenseAllocationEnabled?: boolean): Promise<any> {
    const params = new HttpParams()
      .set('licenseAllocationEnabled', licenseAllocationEnabled);

    const allocations = {};

    for (const [key, value] of Object.entries(companyLicenses)) {
      allocations[key] = {
        allocatedProductCode: value.allocatedProductCode,
        allocatedLicenseCount: value.allocatedLicenseCount
      };
    }

    const body = {
      allocationData: JSON.stringify({
        data: allocations
      })
    };

    return firstValueFrom(super.requestAsObservable(companyId, 'PATCH', 'license-allocations', params, body));
  }
}

angular.module('risevision.apps.services')
  .factory('companyService', downgradeInjectable(CompanyApiService));
