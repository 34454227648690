import { Injectable } from '@angular/core';

import type { Company } from './company';
import type { CompanyLicenseInfo } from './company-license-info';
import { PlansService } from 'src/app/components/plans/plans.service';
import { CompanyApiService } from 'src/app/api/services/company-api.service';
import { PlanUtilsService } from 'src/app/components/plans/plan-utils.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { StateService } from '@uirouter/angular';
import { PromiseUtilsService } from 'src/app/shared/services/promise-utils.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyAllocationService {

  licenseInfo: CompanyLicenseInfo = {};
  allocationMap: { [key: string]: Company } = {};
  licenseAllocationEnabled: boolean;
  loading: boolean = true;

  assignablePlan = {
    unlimited: [
      this.plansService.getUnlimitedPlan().productCode,
			this.plansService.getPlanByType('unlimited').productCode
    ],
	  enterprise: [
      this.plansService.getEnterprisePlan(false).productCode,
			this.plansService.getEnterprisePlan(true).productCode
    ]
  };

  availablePlan = {};

  constructor(
    private companyApiService: CompanyApiService,
    private currentPlanService: CurrentPlanService,
    private plansService: PlansService,
    private planUtilsService: PlanUtilsService,
    private companyStateService: CompanyStateService,
    private stateService: StateService,
    private promiseUtilsService: PromiseUtilsService
  ) { }

  init(): Promise<void> {
    this.allocationMap = {};
    this.licenseInfo = {};
    this.loading = true;

    return this.companyStateService.reloadSelectedCompany().then(() => {
      this.licenseAllocationEnabled = this.companyStateService.getCopyOfSelectedCompany().licenseAllocationEnabled;
      return this.companyApiService.getLicenseAllocationInfo(this.companyStateService.getSelectedCompanyId())
        .then((result) => {
          if (result && result.item && result.item.licenseInfo) {
            this.licenseInfo = result.item.licenseInfo;
          }
        });
    }).finally(() => {
      this.loading = false;
    });
  }

  get isManagedByParent(): boolean {
    const companyId = this.companyStateService.getCopyOfSelectedCompany().id;
    return this.licenseInfo.ENTERPRISE?.subscriptionBillToId !== undefined && this.licenseInfo.ENTERPRISE.subscriptionBillToId !== companyId ||
      this.licenseInfo.UNLIMITED?.subscriptionBillToId !== undefined && this.licenseInfo.UNLIMITED.subscriptionBillToId !== companyId;
  }

  getProductCode(type?: string): string {
    if (type === 'unlimited') {
      return this.plansService.getUnlimitedPlan().productCode;
    } else if (type === 'enterprise') {
      return this.plansService.getEnterprisePlan(false).productCode;
    } else {
      return '';
    }
  }

  getAssignablePlan(productCode: string): string {
    if (productCode === this.plansService.getUnlimitedPlan().productCode ||
      productCode === this.plansService.getPlanByType('unlimited').productCode) {
      return 'unlimited';
    } else if (productCode === this.plansService.getEnterprisePlan(false).productCode ||
      productCode === this.plansService.getEnterprisePlan(true).productCode) {
      return 'enterprise';
    } else {
      return '';
    }
  }

  getLicenseType(company: Company): string {
    if (!this.licenseAllocationEnabled) {
      return 'shared';
    }
    if (this.planUtilsService.isSelfManagedPlan(company)) {
      return 'managed';
    }
    return this.getAssignablePlan(company.allocatedProductCode);
  }

  getPurchasedLicenses(type?: string): number {
    if (type) {
      const info = this.licenseInfo[type.toUpperCase()];
      return info ? info.purchasedLicenseCount : 0;
    }
    return 0;
  }

  getAllocatedLicenses(type?: string): number {
    if (!this.licenseAllocationEnabled || !type) {
      return 0;
    }
    const info = this.licenseInfo[type.toUpperCase()];
    return info ? info.allocatedLicenseCount : 0;
  }

  getRemainingLicenses(type?: string): number {
    if (type) {
      const info = this.licenseInfo[type.toUpperCase()];
      return info ? this.licenseAllocationEnabled ? Math.max(info.purchasedLicenseCount - info.allocatedLicenseCount, 0) : info.purchasedLicenseCount : 0;
    }
    return 0;
  }

  getRequiredLicenses(type?: string): number {
    if (type) {
      const info = this.licenseInfo[type.toUpperCase()];
      return info && this.licenseAllocationEnabled ? Math.max(info.allocatedLicenseCount - info.purchasedLicenseCount, 0) : 0;
    }
    return this.getRequiredLicenses('enterprise') + this.getRequiredLicenses('unlimited');
  }

  getRequiredLicensesText(type: string): string {
    const count = this.getRequiredLicenses(type);
    return count ? `${count} ${type.charAt(0).toUpperCase()}${type.slice(1).toLowerCase()} license${count > 1 ? 's' : ''}` : '';
  }

  private updateTotals(type: string, count: number): void {
    const info = this.licenseInfo[type.toUpperCase()];
    if (info) {
      info.allocatedLicenseCount = Math.max(info.allocatedLicenseCount + count, 0);
      info.usedLicenseCount = Math.min(info.purchasedLicenseCount, info.allocatedLicenseCount);
    } else {
      this.licenseInfo[type.toUpperCase()] = {
        purchasedLicenseCount: 0,
        allocatedLicenseCount: count,
        usedLicenseCount: 0
      };
    }
  }

  allocateType(company: Company, type?: string): void {
    if (this.getLicenseType(company) !== type) {
      const oldPlan = this.getAssignablePlan(company.allocatedProductCode);
      const count = company.allocatedLicenseCount;

      company.allocatedProductCode = this.getProductCode(type);

      if (type === 'unlimited' || type === 'enterprise') {
        company.allocatedLicenseCount = 1;
        this.updateTotals(oldPlan, -count);
        this.updateTotals(type, 1);

      } else if (type === 'none' || !type) {
        company.allocatedLicenseCount = 0;
        this.updateTotals(oldPlan, -count);
      }

      this.allocationMap[company.id] = company;
    }
  }

  allocateCount(company: Company, count: number): void {
    const plan = this.getAssignablePlan(company.allocatedProductCode);
    if (plan) {
      const change = count - (company.allocatedLicenseCount || 0);
      company.allocatedLicenseCount = count;
      this.allocationMap[company.id] = company;
      this.updateTotals(plan, change);
    }
  }

  purchaseLicenses(requireLicenses: boolean) {
    const requiredEnterprise = this.getRequiredLicenses('enterprise');
    const requiredUnlimited = this.getRequiredLicenses('unlimited');
    const isK12 = this.companyStateService.isK12Customer();

    if ((!requireLicenses || requiredEnterprise + requiredUnlimited > 0) && !this.isManagedByParent) {
      if (this.currentPlanService.isOnTrial()) {
        this.stateService.go('apps.purchase.home', {
          enterprise: requiredEnterprise,
          unlimited: isK12 ? requiredUnlimited : null
        });
      } else {
        const enterpriseSubscription = this.licenseInfo.ENTERPRISE?.subscriptionId;
        const unlimitedSubscription = this.licenseInfo.UNLIMITED?.subscriptionId;
        let subscriptionIds = enterpriseSubscription ? enterpriseSubscription : '';
        if (unlimitedSubscription) {
          subscriptionIds += enterpriseSubscription ? ',' : '';
          subscriptionIds += unlimitedSubscription;
        }

        this.stateService.go('apps.purchase.licenses.add', {
          purchaseAction: 'add',
          subscriptionId: subscriptionIds,
          enterprise: requiredEnterprise,
          unlimited: isK12 ? requiredUnlimited : null
        });
      }
    }
  }

  saveAllocations(): Promise<any> {
    return this.companyApiService.allocateLicenses(this.companyStateService.getSelectedCompanyId(), this.allocationMap, this.licenseAllocationEnabled)
      .then(() => {
        if (!this.currentPlanService.isOnTrial()) {
          const company = this.companyStateService.getCopyOfSelectedCompany();
          const companyAllocation = this.allocationMap[company.id];
          if (companyAllocation || this.licenseAllocationEnabled !== company.licenseAllocationEnabled) {
            return this.promiseUtilsService.delay(2000).then(() => {
              return this.companyStateService.reloadSelectedCompany();
            });
          }
        }
      }).then(() => {
        this.purchaseLicenses(true);
      });
  }

}
